<template>
  <el-row>
    <el-row>
      <div class="backtop">
        <span @click="gotoBack">
          <i class="el-icon-arrow-left" style="cursor: pointer">返回上一级</i>
        </span>
      </div>
    </el-row>
    <el-row class="table">
      <el-table
        border
        fit
        highlight-current-row
        :data="dataList"
        class="tb-list"
        style="margin-top: 30px;"
      >
        <el-table-column label="提交人ID" align="center" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.loginName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属问题ID" align="center" width="230">
          <template slot-scope="scope">
            <span>{{ scope.row.problem_id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属问题标题" align="left" min-width="450">
          <template slot-scope="scope">
            <span>{{ scope.row.problemName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结果" align="center" width="230">
          <template slot-scope="scope">
            <span
              @click="subDetail(scope.row)"
              class="type-span"
              :style="'backgroundColor' + ':' + getStateColor(scope.row.result)"
            >
              {{ getSubType(scope.row.result) }}
              <span
                v-if="scope.row.pass_rate && scope.row.pass_rate !== 1"
              >{{ (Number(scope.row.pass_rate)).toFixed(1) * 100 }}%</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="详情" align="center" width="230">
          <template slot-scope="scope">
            <el-row style="line-height:25px;">
              <i class="el-icon-time ico-time"></i>
              <span>{{scope.row.time}}  ms</span>
              <i class="el-icon-coin ico-bulk"></i>
              <span>{{scope.row.memory}}  KB</span>
              <br />
              <div style="margin-left: 8px;">
                <i class="el-icon-document"></i>
                <span style="margin-left: 5px;" >{{scope.row.code_length}}</span> B
                <span style="margin-left: 5px;">{{getLanText(scope.row.language)}}</span>
              </div>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="提交时间" align="center" width="230">
          <template slot-scope="scope">
            <span>{{ scope.row.in_date | dateFormat }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </el-row>
</template>

<script>
import { mySubmitHistory } from '@/api/research/quesBank'

export default {
  props: {
    RecordTit: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      inputVal: "",
      dataList: [],
      select: null,
      contest_id: "",
      lesson_id: ""
    };
  },
  created() {
    this.contest_id = this.$route.query.contest_id;
    // this.lesson_id = this.$route.query.lesson_id;
    this.getSubRecordList();
  },
  methods: {
    gotoBack() {
      this.$router.back(-1);
    },
    // 获取提交记录列表
    async getSubRecordList() {
      const res = await mySubmitHistory({
        contest_id: this.contest_id,
        // lesson_id: this.lesson_id
      })
      this.dataList = res.body
    },
    getSubType(state) {
      switch (state) {
        case 0:
          return "等待";
          break;
        case 1:
          return "等待重判";
          break;
        case 2:
          return "编译中";
          break;
        case 3:
          return "运行并评判";
          break;
        case 4:
          return "正确";
          break;
        case 5:
          return "格式错误";
          break;
        case 6:
          return "答案错误";
          break;
        case 7:
          return "时间超限";
          break;
        case 8:
          return "内存超限";
          break;
        case 9:
          return "输出超限";
          break;
        case 10:
          return "运行错误";
          break;
        case 11:
          return "编译错误";
          break;
        case 12:
          return "编译成功";
          break;
        case 13:
          return "运行完成";
          break;
        default:
          return "未知状态";
      }
    },
    getStateColor(state) {
      switch (state) {
        case 4:
          return "#4DA54D";
          break;
        case 12:
          return "#4DA54D";
          break;
        case 13:
          return "#4DA54D";
          break;
        case 5:
          return "#CE433F";
          break;
        case 6:
          return "#CE433F";
          break;
        case 10:
          return "#CE433F";
          break;
        case 11:
          return "#CE433F";
          break;
        default:
          return "#EB9316";
      }
    },

    // 获取语言
    getLanText(state) {
      switch (state) {
        case 0: {
          return "C";
        }
        case 1: {
          return "C++";
        }
        case 2: {
          return "Pascal";
        }
        case 4: {
          return "Ruby";
        }
        case 5: {
          return "Bash";
        }
        case 6: {
          return "Python";
        }
        case 7: {
          return "php";
        }
        case 8: {
          return "perl";
        }
        case 9: {
          return "c#";
        }
        case 10: {
          return "objectiveC";
        }
        case 11: {
          return "freeBasic";
        }
        case 12: {
          return "scheme";
        }
        case 13: {
          return "Clang";
        }
        case 14: {
          return "Clang++";
        }
        case 15: {
          return "Lua";
        }
        case 16: {
          return "JavaScript";
        }
        case 17: {
          return "Go";
        }
      }
    },

    // 跳转详情页面
    subDetail(row) {
      row.showTop = "show";
      let routeUrl = this.$router.resolve({
        path: "/research/question-subDetails",
        query: {
          resultData: JSON.stringify(row),
        }
      });
      window.open(routeUrl .href, '_blank');
    },

    // 搜索
    search() {
      this.getSubRecordList();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";

.backtop {
  width: 100%;
  height: 50px;
  color: #ffffff;
  line-height: 50px;
  padding-left: 20px;
  font-size: 16px;
  background-color: rgb(90, 90, 90);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.td-input {
  width: 300px;
}

.ico-time {
  margin-right: 8px;
}

.ico-bulk {
  margin-left: 13px;
  margin-right: 8px;
}

.type-span {
  display: inline-block;
  padding: 6px 19px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
}
</style>
